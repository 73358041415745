.artists-page {
    background-color: #000000;
    color: white;
    position: relative;
    min-height: 100vh;
  }
  
  .content-wrapper {
    position: relative;
    z-index: 2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .main-content {
    flex: 1;
    padding: 120px 20px;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
  }
  
  .main-content h1 {
    font-size: clamp(40px, 6vw, 80px);
    text-align: center;
    margin-bottom: 80px;
  }
  
  .artists-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
  }
  
  .artist-card {
    background: rgba(255,255,255,0.05);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .artist-card:hover {
    transform: scale(1.02);
  }
  
  .image-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    background: #1a1a1a;
  }
  
  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: rgba(255,255,255,0.2);
  }
  
  .artist-info {
    padding: 20px;
  }
  
  .artist-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .social-links {
    display: flex;
    gap: 10px;
  }
  
  .social-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255,255,255,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
  }
  
  .social-icon:hover {
    background: rgba(255,255,255,0.2);
  }
  
  .social-icon img {
    width: 20px;
    height: 20px;
    filter: brightness(0) invert(1);
  }