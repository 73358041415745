@keyframes flicker {
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
    opacity: 1;
  }
  20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
    opacity: 0.33;
  }
}

.flicker-text {
  color: #808080;
  animation: flicker 1ms linear infinite;
}

.stat-card {
  transition: transform 0.3s ease;
  cursor: pointer;
}

.stat-card:hover {
  transform: scale(1.05);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background: #1a1a1a;
}

a {
  transition: opacity 0.3s ease;
}

a:hover {
  opacity: 0.8;
}

.stat-card img {
  transition: opacity 0.3s ease;
}

.stat-card:hover img {
  opacity: 0.8;
}
